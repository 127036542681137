import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import {
  parseComponentProp,
  parseStyleProps,
} from '@haaretz/htz-css-tools';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import { textInputVariantType, } from '../TextInput/textInputVariantType';

import { stylesPropType, } from '../../propTypes/stylesPropType';
import HtzLink from '../HtzLink/HtzLink';

const TextInputVariants = textInputVariantType;

const propTypes = {
  /** true if there is a validation error */
  isError: PropTypes.bool,
  /**
   * Id used to connect the note to input with aria-describedby for a11y reasons,
   * default will generate random id
   */
  noteId: PropTypes.string,
  /** the note text, parent component responsible for changing the text when there is an error */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  /** The TextInput stylistic variant */
  variant: PropTypes.oneOfType([
    TextInputVariants,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: TextInputVariants.isRequired,
      })
    ),
  ]),
  linkAfterText: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    prefixText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, ]),
    suffixText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, ]),
  }),
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
};

const defaultProps = {
  isError: false,
  noteId: null,
  text: null,
  linkAfterText: null,
  variant: 'primary',
  miscStyles: null,
};

const linkAfterTextStyle = ({ theme, }) => ({
  color: theme.color('noteLink', 'color'),
  ':visited': {
    color: theme.color('noteLink', 'color'),
  },
  ':hover': {
    color: theme.color('noteLink', 'color'),
  },
});

function setVariant(prop, variant, getColor, isError, linkAfterText) {
  return {
    color: isError
      ? getColor('input', `${variant}ErrorTextNote`)
      : linkAfterText ? getColor('input', 'primaryTextNote')
        : getColor('input', `${variant}TextNote`),
  };
}

function Note({ variant, isError, text, noteId, miscStyles, linkAfterText, }) {
  const { css, theme, } = useFela();
  return (
    <span
      className={css({
        display: 'block',
        fontFamily: theme.fontStacks[theme.framedFont],
        extend: [
          theme.type(-3),
          parseComponentProp(
            undefined,
            variant,
            theme.mq,
            setVariant,
            theme.color,
            isError,
            linkAfterText,
          ),
          ...(miscStyles
            ? parseStyleProps(miscStyles, theme.mq, theme.type)
            : []),
        ],
      })}
      id={noteId}
    >
      {text}
      {linkAfterText ? (
        <>
          <HtzLink
            className={css(linkAfterTextStyle)}
            href={linkAfterText.link}
            content={linkAfterText.text}
            onClick={linkAfterText.onClick}
          />
          {linkAfterText.suffixText ? ` ${linkAfterText.suffixText}` : ''}
        </>
      ) : null}
    </span>
  );
}

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

export default Note;
