// @flow
import React, { Fragment, } from 'react';
import type { ChildrenArray, Element, Node, } from 'react';
import { FelaComponent, FelaTheme, } from 'react-fela';
import {
  AriaLive,
  DeviceTypeInjector,
  LayoutContainer,
  AdSlot,
  Grid,
  GridItem,
  IconFinanceLogo,
  Masthead,
  Footer,
  SideBar,
  GetComponentProvider,
  useSlotData,
  WebViewExclude,
} from '@haaretz/htz-components';

import NavigationBar from '../components/NavigationBar/NavigationBar';
import RowItem from '../components/RowItem/RowItem';
import PageRow from '../components/PageRow/PageRow';
import SearchBar from '../components/SearchBar/SearchBar';
import getElements from '../utils/getPageElementsBS';
import MobileSearch from '../components/MobileSearch/MobileSearch';
import FinanceDataGetter from './FinanceDataGetter';
import useAllAdUnits from '../components/AdManager/AdUnitsProvider';
import useAdUnitPathTemplate, { createAdSlotProps, } from '../components/AdManager/useAdUnitPathTemplate';

const banners = [ 'native1', 'native2', 'native3', 'native4', ];

type Props = {
  children: ChildrenArray<Element<any>>,
  section: ?string,
};

// TODO: Move all elements to CMS
// const menuItems = {
//   menu: {
//     promotions: [
//       {
//         name: 'רכשו מינוי',
//         url: 'https://promotions.themarker.com/promotions-page/product',
//       },
//     ],
//     sites: [
//       {
//         name: 'Haaretz Com',
//         url: 'http://www.haaretz.com/',
//       },
//       {
//         name: 'TheMarker',
//         url: 'http://www.themarker.com/',
//       },
//       {
//         name: 'הארץ',
//         url: 'http://www.haaretz.co.il/',
//       },
//     ],
//     items: [
//       {
//         name: 'ראשי',
//         url: '/',
//       },
//       {
//         name: 'מניות',
//         url: '/stock',
//       },
//       {
//         name: 'ארביטראז\'',
//         url: '/arbitrage',
//       },
//       {
//         name: 'קרנות נאמנות',
//         url: '/mtf',
//       },
//       {
//         name: 'קרנות סל',
//         url: '/etf',
//       },
//       {
//         name: 'אג"ח',
//         url: '/bond',
//       },
//       {
//         name: 'אופציות',
//         url: '/options',
//       },
//       {
//         name: 'מט"ח',
//         url: '/exchange',
//       },
//       {
//         name: 'מטבעות דיגיטליים',
//         url: '/crypto',
//       },
//     ],
//   },
// };

MainLayout.defaultProps = {
  section: null,
};

function MainLayout({
  children,
  section,
}: Props): Node {
  const headerData = useSlotData('header');
  const header = headerData || [];
  const pageNavigation = header.find(item => item.inputTemplate === 'PageNavigation');

  const adUnits = useAllAdUnits();
  const adUnitPathTemplate = useAdUnitPathTemplate();

  return (
    <FelaTheme>
      {theme => (
        <GetComponentProvider value={getElements}>
          <Fragment>
            <AriaLive />
            <DeviceTypeInjector />
            <WebViewExclude>
              <Masthead
                pageType={section === 'index' ? 'homepage' : 'finance'}
                searchComponent={{
                  mobile: MobileSearch,
                  desktop: SearchBar,
                }}
                hideSearch
                menuItems={pageNavigation?.menuItems || []}
                rowBgc={[
                  { until: 's', value: 'transparent', },
                  { from: 's', value: [ 'neutral', '-6', ], },
                ]}
                wrapperMiscStyles={{
                  backgroundColor: [ { until: 's', value: theme.color('white'), }, ],
                  backgroundImage: 'none',
                  borderBottom: [ '1px', 1, 'solid', theme.color('masthead', 'border'), ],
                }}
              />
            </WebViewExclude>
            <FelaComponent
              style={{
                backgroundColor: theme.color('neutral', '-6'),
                ...theme.mq({ until: 's', }, { paddingTop: '3rem', }),
              }}
            >
              <WebViewExclude>
                <LayoutContainer
                  bgc="transparent"
                  miscStyles={{
                    paddingStart: [ { until: 's', value: '2rem', }, ],
                    paddingEnd: [ { until: 's', value: '2rem', }, ],
                  }}
                >
                  <NavigationBar
                    section={section}
                    miscStyles={{
                      display: [ { until: 'l', value: 'none', }, ],
                    }}
                  />
                  <FelaComponent
                    style={{
                      marginBottom: '1rem',
                      ...theme.mq({ from: 's', }, { display: 'none', }),
                    }}
                  >
                    <AdSlot {...createAdSlotProps(adUnits['finance.themarker.com.interstitial'], adUnitPathTemplate)} />
                  </FelaComponent>
                  <FelaComponent
                    style={{
                      marginBottom: '2rem',
                      paddingTop: '1px',
                    }}
                  >
                    <AdSlot
                      {...createAdSlotProps(adUnits['finance.themarker.com.billboard'], adUnitPathTemplate)}
                      wrapperMiscStyles={{
                        ':not(:empty)': {
                          paddingTop: 'calc(4rem - 1px)',
                        },
                      }}
                    />
                  </FelaComponent>
                </LayoutContainer>
              </WebViewExclude>
              <LayoutContainer
                tagName="main"
                bgc="transparent"
                miscStyles={{
                  display: [ { from: 'l', value: 'flex', }, ],
                  paddingStart: [
                    { until: 'l', value: '2rem', },
                    { from: 'l', until: 'xl', value: '11rem', },
                  ],
                  paddingEnd: [
                    { until: 'l', value: '2rem', },
                    { from: 'l', until: 'xl', value: '11rem', },
                  ],
                }}
              >
                <FelaComponent
                  style={{
                    extend: [
                      theme.mq({ from: 'xl', }, {
                        width: 'calc(100% - 300px - 8rem)',
                        paddingEnd: '10rem',
                      }),
                    ],
                  }}
                >
                  {children}
                  <PageRow miscStyles={{ marginBottom: '0', }}>
                    <RowItem
                      title="Labels"
                      isCommercial
                    >
                      <Grid
                        gutter={2}
                        miscStyles={{ marginTop: '2rem', }}
                      >
                        {
                          banners.map(banner => (
                            <GridItem
                              width={[
                                { until: 's', value: 1, },
                                { from: 's', until: 'l', value: 1 / 2, },
                                { from: 's', value: 1 / banners.length, },
                              ]}
                            >
                              <AdSlot {...createAdSlotProps(adUnits[`finance.themarker.com.${banner}`], adUnitPathTemplate)} />
                            </GridItem>
                          ))
                        }
                      </Grid>
                    </RowItem>
                  </PageRow>
                </FelaComponent>
                <FelaComponent
                  style={{
                    flexShrink: '0',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'flex-start',
                    extend: [
                      theme.mq({ until: 'xl', }, { display: 'none', }),
                      theme.mq({ from: 'xl', }, { width: 'calc(300px + 8rem)', }),
                    ],
                  }}
                >
                  {({ className, }) => (
                    <aside className={className}>
                      <FelaComponent
                        style={{
                          height: '100%',
                          left: '0',
                          position: 'absolute',
                          top: '0',
                        }}
                      >
                        <SideBar />
                      </FelaComponent>
                    </aside>
                  )}
                </FelaComponent>
              </LayoutContainer>
            </FelaComponent>
            <WebViewExclude>
              <Footer
                contentId="TheMarker.Element.FooterElement"
                logo={({ size, miscStyles, }) => (
                  <IconFinanceLogo size={size} color="primary" miscStyles={miscStyles} />
                )}
                logoAltText="פייננס"
              />
            </WebViewExclude>
            <FelaComponent
              style={{
                extend: [
                  theme.mq({ until: 's', }, {
                    marginBottom: [
                      theme.mobileStyle.menuHeight,
                      `calc(${theme.mobileStyle.menuHeight} + env(safe-area-inset-bottom, 0))`,
                    ],
                  }),
                  theme.mq({ from: 'l', }, {
                    display: 'none',
                  }),
                ],
              }}
            >
              <PageRow miscStyles={{ marginBottom: '0', }}>
                <AdSlot {...createAdSlotProps(adUnits['finance.themarker.com.stripbottom'], adUnitPathTemplate)} />
              </PageRow>
            </FelaComponent>
          </Fragment>
        </GetComponentProvider>
      )}
    </FelaTheme>
  );
}

export default (props: Props) => (<FinanceDataGetter><MainLayout {...props} /></FinanceDataGetter>);
